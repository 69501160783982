import TableWrapper from '../BaseComponents/Table/TableWrapper';
import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';

export default function ProductsTable(props: any) {

    const { registration, setRegistration } = RegistrationState();

    const rowDeleteClick = (row: any) => {
        setRegistration({
            ...registration
            , products: registration.products.filter(x => x !== row)
        });
    }

    return (<>
        {registration.products.length > 0 &&
            <>
                <TableWrapper settings={props} products={registration.products} rowDeleteClick={rowDeleteClick} hideActions={props.hideActions}></TableWrapper>
                <br></br>
            </>}
    </>
    );
}