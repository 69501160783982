import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

export default function ProductsCard(props: any) {

    const { registration, setRegistration } = RegistrationState();

    const rowDeleteClick = (row: any) => {
        setRegistration({
            ...registration
            , products: registration.products.filter(x => x !== row)
        });
    }

    return (<>
        {registration.products.length > 0 &&
            <>
                <Row>
                    {registration.products.map((row: any, i: number) => (
                        <Col key={i} md="6" style={{ marginBottom: "10px" }}>
                            <Card variant="outlined" sx={{ minWidth: 275 }}>
                                <CardContent>
                                    {props?.cols?.map((col: any, i2: number) => (
                                        <p key={i2}>
                                            <span style={col?.header?.cssStyle}>{col?.header?.title}: </span>
                                            <span style={col?.body?.cssStyle}>{row[col.propName]}</span>
                                        </p>
                                    ))}
                                </CardContent>
                                {!props.hideActions &&
                                    <CardActions>
                                        <Button color="error" style={{ margin: "auto", width: "100%" }} onClick={() => rowDeleteClick(row)}>
                                            <DeleteIcon />
                                        </Button>
                                    </CardActions>}
                            </Card>
                        </Col>
                    ))}
                </Row>
                <br></br>
            </>}
    </>
    );
}