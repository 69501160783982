import { createContext, useContext, useState } from "react";

export type RegistrationContextState = {
    countryISO2?: string,
    salutation?: string,
    firstName?: string,
    lastName?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    city?: string,
    stateProvince?: string,
    postal?: string,
    email?: string,
    phone?: string,
    extendedProperties?: any,
    products: any[]
}

const regCtxDefaultValue = {
    registration: { products: [] },
    setRegistration: (state: RegistrationContextState) => { } // noop default callback
};


const RegistrationContext = createContext(regCtxDefaultValue);

export default function RegistrationContextProvider(props: any) {
    const [registration, setRegistration] = useState(regCtxDefaultValue.registration);
    return (
        //@ts-ignore
        <RegistrationContext.Provider value={{ registration, setRegistration }}>
            {props.children}
        </RegistrationContext.Provider>
    );
}

export const RegistrationState = () => {
    return useContext(RegistrationContext);
}