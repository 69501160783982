import { useSearchParams } from "react-router-dom";
import FormJsonSchema from '../BaseComponents/FormJsonSchema/FormJsonSchema';
import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';
import { useNavigate } from 'react-router-dom';

function Registration(props: any) {
    let [searchParams] = useSearchParams();
    const { registration, setRegistration } = RegistrationState();
    const navigate = useNavigate();

    const onSubmit = (obj: any) => {
        setRegistration({
            ...obj.formData,
            products: registration.products
        });
        navigate('/' + props.nextPage + '?' + searchParams.toString());
    };

    return (<>
        <FormJsonSchema
            showErrorList={props.showErrorList}
            schema={props.schema}
            uiSchema={props.uiSchema}
            onSubmit={onSubmit}
        />
        <br></br>
    </>
    );
}

export default Registration;
