import styles from './styles.module.css';

import { StepStates, StepProgressProps } from './models';

function StepProgressBar(props: StepProgressProps): JSX.Element {
  const {
    steps
  } = props;

  return (
    <div className={`${styles['progress-bar-wrapper']}`}>
      <ul className={`${styles['step-progress-bar']}`}>
        {steps.map(function (step, i) {
          return (
            <li
              key={i}
              className={`${styles['progress-step']}${step.state === StepStates.COMPLETED ? ` ${styles.completed}` : ''
                }${step.state === StepStates.CURRENT ? ` ${styles.current}` : ''}${step.state === StepStates.ERROR ? ` ${styles['has-error']}` : ''
                }`}
            >
              {step.state === StepStates.COMPLETED && (
                <span className={styles['step-icon']}>
                  <svg
                    width="1rem"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 3.5L4.5 7.5L12 1" stroke="white" strokeWidth="1.5" />
                  </svg>
                </span>
              )}
              {step.state === StepStates.ERROR && <span className={styles['step-icon']}>!</span>}
              {step.state !== StepStates.COMPLETED && step.state !== StepStates.ERROR && (
                <span className={styles['step-index']}>{i + 1}</span>
              )}
              <div className={`${styles['step-label']}`}>
                {step.label}
              </div>
            </li>
          );
        })}
      </ul>

      <div className={`${styles['step-content']}`}>
      </div>
    </div>
  );
}

export default StepProgressBar;
