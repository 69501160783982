import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './TopNavBarStyle1.css';
import { useSearchParams } from "react-router-dom";
import Markdown from 'markdown-to-jsx'

function TopNavBarStyle1(props: any) {
  /* Whole code to deal with routes and URLs needs re-writing, not putting time on it just because its a POC*/

  let [searchParams] = useSearchParams();

  function replaceUrlParam(url: string, paramName: string, paramValue: string) {
    var pattern = new RegExp('(\\?|\\&)(' + paramName + '=).*?(&|$)')
    var newUrl = url
    if (url.search(pattern) >= 0) {
      newUrl = url.replace(pattern, '$1$2' + paramValue + '$3');
    }
    else {
      newUrl = newUrl + (newUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
    }
    return newUrl
  }

  function onClickLang(langCode: string, langDesc: string) {
    const currentSiteName = searchParams.get("site");
    if (searchParams.get("lang")) {
      window.location.replace(replaceUrlParam(window.location.href, "lang", langCode));
    } else {
      if (window.location.href.includes("#")) {
        window.location.replace(window.location.href + '?lang=' + langCode + (currentSiteName ? `&site=${currentSiteName}` : ''));
      } else {
        window.location.replace(window.location.href + '#/?lang=' + langCode + (currentSiteName ? `&site=${currentSiteName}` : ''));
      }
    }
    window.location.reload();
  }

  function onClickPage(item: any) {
    const currentSiteName = searchParams.get("site");
    if (item.isExternal) {
      window.open(item.link, '_blank')?.focus();
    } else {
      window.location.replace(window.location.origin + '/#/' + item.link + '?lang=' + currentLangCode() + (currentSiteName ? `&site=${currentSiteName}` : ''));
    }
  }

  function currentLangCode() {
    return (searchParams.get("lang") || 'en').toLowerCase();
  }

  return (<div style={{ direction: "ltr", ...props.settings.cssStyle }} id="navbar-style1">
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand onClick={() => onClickPage({ link: "" })}><Markdown>{props.settings.homeContent}</Markdown></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {props.settings?.navigation?.map((item: any, i: number) =>
              <Nav.Link key={i} onClick={() => onClickPage(item)}>{item.displayName}</Nav.Link>
            )}

            {props?.languages?.length > 1 && <NavDropdown title={props.languages.find((x: any) => x.code === currentLangCode())?.displayName} id="basic-nav-dropdown">
              {props.languages.filter((x: any) => x.code !== currentLangCode()).map((item: any, i: number) =>
                <NavDropdown.Item key={i} onClick={() => onClickLang(item.code, item.displayName)}>{item.displayName}</NavDropdown.Item>
              )}
            </NavDropdown>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {props.settings.topSiteText?.visible &&
      <Container className="top-site-text" style={props.settings.topSiteText.cssStyle}>
        <Markdown>{props.settings.topSiteText.content}</Markdown>
      </Container>}
  </div>
  );
}

export default TopNavBarStyle1;