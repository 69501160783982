import { Container } from "@mui/material";

export default  function FooterStyle1(props: any) {
    return (
        <Container style={{ maxWidth: "880px" }}>
        <footer style={{ maxWidth: "880px", textAlign: "justify", fontSize: "13px", color: "grey", marginBottom: "25px" }}>
            {props.settings.copyright} <a href={props.settings.privacyPolicyLink} target='_blank' rel="noreferrer">{props.settings.privacyPolicyText}</a>
        </footer>
        </Container>
    );
}
