import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StepProgressBar from "../BaseComponents/StepProgressBar";
import { StepStates } from "../BaseComponents/StepProgressBar/models";

function ProgressBarStyle1(props: any) {
    const location = useLocation();

    const [processedSteps, setProcessedSteps] = useState([{
        label: "",
        state: StepStates.CURRENT
    }]);
    const [showBar, setShowBar] = useState(true);

    useEffect(() => {
        let currentIndex = props.siteSettings.pages.find((x: any) => x.path === location.pathname)?.progressBarIndex || 0;
        setShowBar(currentIndex !== -1);

        if (currentIndex !== -1 && props.siteSettings && props.siteSettings.progressBar && props.siteSettings.progressBar.steps) {
            let steps = props.siteSettings.progressBar.steps.map((x: any, i: number) => {
                return {
                    label: x.label,
                    state: (i <= currentIndex ? StepStates.CURRENT : StepStates.NOT_STARTED)
                };
            });
            setProcessedSteps(steps);
        }
    }, [location, props]);

    return (
        <div style={{ direction: "ltr" }}>
            {props.siteSettings.progressBar.visible &&
                showBar &&
                <StepProgressBar steps={processedSteps} />
            }
        </div>
    );
}

export default ProgressBarStyle1;
