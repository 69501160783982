import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';

export default function PhotoUpload(props: any) {

    const { registration, setRegistration } = RegistrationState();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const onFileUploadChange = (e: any, product: any, index: number, propName: string) => {
        const file = e.target?.files?.[0];
        if (!file) return;

        let products = registration.products.map((item: any, i: number) => {
            if (i === index) {
                item[propName] = {
                    name: file.name,
                    size: file.size,
                    type: file.type
                };
            }
            return item;
        });
        setRegistration({
            ...registration,
            products
        });
    }
    const onFileClear = (product: any, index: number, propName: string) => {
        let products = registration.products.map((item: any, i: number) => {
            if (i === index) {
                item[propName] = null;
            }
            return item;
        });
        setRegistration({
            ...registration,
            products
        });
    }

    useEffect(() => {
        //just to be able to demo re-upload
        if (registration?.products && registration.products.length === 0) {
            setRegistration({
                "products": [
                    {
                        "purchasedPlatform": "Amazon",
                        "orderNumber": "Example",
                        "modelNumber": "ModelDemo1",
                        "serialNumber": "Example",
                        "purchaseDate": "2024-10-10"
                    }
                ]
            });
        }
    }, [registration, setRegistration]);



    return (<>
        {registration.products.length > 0 &&
            <>
                <Row>
                    {registration.products.map((row: any, i: number) => (
                        <Col key={i} md="12" style={{ marginBottom: "10px" }}>
                            <Card variant="outlined" sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Row>
                                        <Col sm="6">
                                            {props?.cols?.map((col: any, i2: number) => (
                                                <p key={i2}>
                                                    <span style={col?.header?.cssStyle}>{col?.header?.title}: </span>
                                                    <span style={col?.body?.cssStyle}>{row[col.propName]}</span>
                                                </p>
                                            ))}
                                        </Col>
                                        <Col sm="6">
                                            {props?.filesToUpload?.map((fileSettings: any, fileIndex: number) =>
                                                <div key={fileIndex}>
                                                    <small>{fileSettings.label}</small>
                                                    <br />
                                                    <Button style={{ marginTop: "10px" }} component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}   >
                                                        Upload file
                                                        <VisuallyHiddenInput type="file" onChange={(event: any) => onFileUploadChange(event, row, i, fileSettings.propName)} />
                                                    </Button>
                                                    <br />
                                                    <Button color="error" size="small" onClick={() => onFileClear(row, i, fileSettings.propName)}>
                                                        {row?.[fileSettings.propName]?.name &&
                                                            <>
                                                                <ClearIcon fontSize="small" /> {row?.[fileSettings.propName]?.name}
                                                            </>
                                                        }
                                                    </Button>
                                                    <br />
                                                    <br />
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </CardContent>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <br></br>
            </>}
    </>
    );
}