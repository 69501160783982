import { useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';

export default function NextPageButton(props: any) {
    let [searchParams] = useSearchParams();
    const { registration } = RegistrationState();
    const navigate = useNavigate();
    
    const onNextStep = () => {
        window.scrollTo(0,0);
        navigate('/' + props.nextPage + '?' + searchParams.toString());
    };

    return (<>
        {registration.products.length > 0 &&
            <Button variant="contained" onClick={onNextStep}>{props?.nextStepButtonLabel}</Button>}
    </>
    );
}