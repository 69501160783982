import { withTheme } from '@rjsf/core';
import { Theme } from '@rjsf/mui';
import { customizeValidator } from '@rjsf/validator-ajv8';
import localizer from 'ajv-i18n';
import './FormJsonSchema.css'
import { useSearchParams } from "react-router-dom";
import { ObjectFieldTemplateProps, RJSFValidationError } from '@rjsf/utils';
import { Col, Row } from 'react-bootstrap';

const log = (type: string) => console.log.bind(console, type);

export default function FormJsonSchema(props: any) {

    let [searchParams] = useSearchParams();

    const getLocalizer = () => {
        const currentLangCode = (searchParams.get("lang") || 'en').toLowerCase();
        switch (currentLangCode) {
            case "en":
            case "ar":
            case "ca":
            case "cs":
            case "de":
            case "es":
            case "fi":
            case "fr":
            case "hu":
            case "id":
            case "it":
            case "ja":
            case "ko":
            case "nb":
            case "nl":
            case "pl":
            case "pt-BR":
            case "ru":
            case "sk":
            case "sv":
            case "th":
            case "zh":
            case "zh-TW":
                return localizer[currentLangCode];
            default:
                return localizer.en;
        }
    }

    const validator = customizeValidator({}, getLocalizer());
    const Form = withTheme(Theme);

    function transformErrors(errors: any, uiSchema: any) {
        return errors.map((error: RJSFValidationError) => {
            const errorOverwrite = props?.errorsOverwrite?.find((x: any) => x.name === error.name && x.property === error.property);
            if (errorOverwrite?.messageToDisplay) {
                error.message = errorOverwrite?.messageToDisplay;
            }
            return error;
        });
    }

    function RowColsObjectFieldTemplate(props: ObjectFieldTemplateProps) {
        if (props && props.uiSchema && props.uiSchema['ui:grid']) {
            return <>
                {props.uiSchema['ui:grid'].map((row: any, i: number) =>
                    <Row key={i}>
                        {Object.keys(row).map((fieldName: string, i2: number) =>
                            <Col key={i2} md={row[fieldName]} style={{ paddingBottom: "15px" }}>
                                {props?.properties?.find(p => p.name === fieldName)?.content}
                            </Col>)}
                    </Row>
                )}
            </>;
        } else {
            return <>
                {props.title}
                {props.description}
                {props.properties.map((element) => element.content)}
            </>;
        }
    }

    function GetUiSchemaProcessed() {
        if (!props.uiSchema['ui:grid']) {
            props.uiSchema['ui:grid'] = Object.keys(props?.schema?.properties)
                .map(x => {
                    let o = {};
                    //@ts-ignore
                    o[x] = 12;
                    return o;
                })
        }
        return props.uiSchema;
    }

    return (<>
        <Form
            noHtml5Validate={true}
            showErrorList={props.showErrorList || 'bottom'}
            schema={props.schema}
            uiSchema={{ 'ui:ObjectFieldTemplate': RowColsObjectFieldTemplate, ...GetUiSchemaProcessed() }}
            validator={validator}
            onSubmit={props.onSubmit}
            onError={log('errors')}
            transformErrors={transformErrors}
        />
    </>
    );
}

