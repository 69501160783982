import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Box } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import './TableWrapper.css';


export default function TableWrapper(props: any) {
  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <TableContainer component={Paper} style={{ margin: "auto", width: "fit-content", minWidth: "450px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {props?.settings?.cols?.map((col: any, i: number) => (
                  <TableCell key={i} style={col.header.cssStyle}>{col.header.title}</TableCell>
                ))}
                {!props.hideActions &&
                  <TableCell>{props.settings.actionTitle}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.products.map((row: any, i: number) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {props?.settings?.cols?.map((col: any, i2: number) => (
                    <TableCell key={i2} style={col.body.cssStyle}>{row[col.propName]}</TableCell>
                  ))}
                  {!props.hideActions &&
                    <TableCell align="center">
                      <IconButton color="error" onClick={() => props.rowDeleteClick(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
      </Box>
    </Box>
  );
}
