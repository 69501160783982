import { RegistrationState } from '../Services/Context/RegistrationContextProvider';
import './DevToolGodMode.css';


export default function DevToolGodMode(props: any) {

    const { registration } = RegistrationState();

    return <div className='god'>
        <div className='outer-ne-resize'>
            <div className='inner-ne-resize'>
                <div className='content'>
                    <span>"With great power comes great responsibility"</span>
                    <hr />
                    <pre>{JSON.stringify(registration, null, 2)}</pre>
                    <hr />
                    <button onClick={props?.handleFetchData}>Force Configs Refresh</button>
                </div>
            </div>
        </div>
    </div>;
}
