import FormJsonSchema from '../BaseComponents/FormJsonSchema/FormJsonSchema';
import { RegistrationState } from '../../Services/Context/RegistrationContextProvider';

function ProductValidationSingle(props: any) {
    const { registration, setRegistration } = RegistrationState();

    const onSubmit = (obj: any) => {
        setRegistration({
            ...registration,
            products: [obj.formData, ...registration.products]
        });
    };

    return (
        <>
            <FormJsonSchema
                showErrorList={props.showErrorList}
                schema={props.schema}
                uiSchema={props.uiSchema}
                onSubmit={onSubmit}
            />
            <br></br>
        </>
    );
}

export default ProductValidationSingle;
