import Markdown from 'markdown-to-jsx'


function QuestionAnswersStyle1(props: any) {
    return (
        <>
            {props?.questions?.map((item: any, i: number) =>
                <div key={i} style={{ marginBottom: '20px' }}>
                    <Markdown>{`**${props.questionChar}: ${item.question}** <br>**${props.answerChar}:** ${item.answer}`}</Markdown>
                </div>
            )}
        </>
    );
}

export default QuestionAnswersStyle1;
