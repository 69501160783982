import { createElement, useEffect } from 'react';
import ProductValidation from '../Components/ProductValidation/ProductValidation';
import ContentMarkdownWithCols from '../Components/Content/ContentMarkdownWithCols';
import Registration from '../Components/Registration/Registration';
import QuestionAnswersStyle1 from '../Components/Content/QuestionAnswersStyle1';
import ProductsTable from '../Components/ProductsList/ProductsTable';
import NextPageButton from '../Components/NextPageButton/NextPageButton';
import ProductsCard from '../Components/ProductsList/ProductsCard';
import PhotoUpload from '../Components/PhotoUpload/PhotoUpload';

function Page(props: any) {

  useEffect(() => {
    if (props?.settings?.title && document.title !== props.settings.title) {
      document.title = props.settings.title;
    }
  }, [props]);

  function mapType(name: string) {
    //bet this can be simplified, one for later
    switch (name) {
      case "ProductValidation": return ProductValidation;
      case "ProductsTable": return ProductsTable;
      case "ProductsCard": return ProductsCard;
      case "PhotoUpload": return PhotoUpload;
      case "ContentMarkdownWithCols": return ContentMarkdownWithCols;
      case "Registration": return Registration;
      case "QuestionAnswersStyle1": return QuestionAnswersStyle1;
      case "NextPageButton": return NextPageButton;
      default: return "pre";
    }
  }

  return (
    <>
      {props.settings.components.map((item: any, index: number) => {
        let p = { ...item.args, key: index };
        if (item.type !== "pre") {
          p.currentlanguage = props.currentlanguage;
        }
        return createElement(mapType(item.type), p);
      })}
    </>
  );
}

export default Page;
