export enum StepStates {
  NOT_STARTED = 'not_started',
  CURRENT = 'current',
  ERROR = 'error',
  COMPLETED = 'completed'
}

export interface ProgressStep {
  label: string;
  state?: StepStates;
}

export interface StepProgressProps {
  steps: ProgressStep[];
}

export interface ReducerAction {
  type: string;
  payload: { index: number; state: StepStates };
}
