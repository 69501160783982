import { Col, Row } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx'


function ContentMarkdownWithCols(props: any) {
    return (
        <Row>
            {props.cols.map((item: any, i: number) =>
                <Col key={i} style={{ textAlign: "justify", ...item.cssStyle }} md={item.size?.md}>
                    <Markdown>{item.content}</Markdown>
                </Col>
            )}
        </Row>
    );
}

export default ContentMarkdownWithCols;
