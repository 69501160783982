import { Container } from "@mui/material";

export default function FooterStyle2(props: any) {
    return (
        <div style={{ paddingBottom: "45px", paddingTop: "10px", ...props.settings.cssStyle }}>
            <Container>
                <footer style={{ fontSize: "13px", color: "inherit", float: "right" }}>
                    {props.settings.copyright} <a href={props.settings.privacyPolicyLink} target='_blank' rel="noreferrer">{props.settings.privacyPolicyText}</a>
                </footer>
            </Container>
        </div>
    );
}
